import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import { NewArticle, BlogTails } from "page_components/blog"

let filtered_posts = []

const News = ({ data }) => {
  const [showAllPosts, setShowAllPosts] = useState(false)

  const breadcrumbs_data = [
    {
      name: "Aktualności",
      href: "/aktualnosci/",
    },
  ]

  const first_article = data?.allWpNews?.nodes[0]
  const articles_list = data?.allWpNews?.nodes.slice(1)

  return (
    <Layout
      seo={{
        title: "Aktualności",
        description: "",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Aktualności" />
      <NewArticle new_article={first_article} />
      <BlogTails
        posts_to_show={articles_list}
        showAllPosts={showAllPosts}
        setShowAllPosts={setShowAllPosts}
        show_button={filtered_posts?.length}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpNews(sort: { fields: date, order: DESC }) {
      nodes {
        title
        slug
        id
        date
        acfNewsPosts {
          listingPhoto {
            altText
            sourceUrl
          }
        }
      }
    }
  }
`

export default News
